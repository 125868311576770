import { defineStore } from 'pinia';
import { z } from 'zod';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { cloneDeep, isEqual, pick } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';
import type { ReplenishmentLocationsFilter } from '../interfaces';

enum Columns {
  Name = 'name',
  ExternalId = 'external_id',
  LocationType = 'location_type',
  City = 'city',
  Sold = 'sold',
  Coverage = 'coverage',
  Replenishment = 'replenishment',
  ExpectedCoverage = 'expected_coverage',
  AvgDailySales = 'avg_daily_sales',
  SourceLocations = 'source_locations',
  Region = 'region',
  TotalProducts = 'total_products',
  TotalSkus = 'total_skus',
  ReplenishmentTime = 'replenishment_time',
  Classifications = 'classifications',
  Brands = 'brands',
  SoldDepleted = 'sold_depleted',
  CoverageDepleted = 'coverage_depleted',
  AvgDailySalesDepleted = 'avg_daily_sales_depleted',
  Constraints = 'constraints',
}

export const useReplenishmentLocationsPageStore = defineStore(
  StoreNames.ReplenishmentLocationsPage,
  () => {
    const columnsVisibility = ref<Record<string, boolean>>({
      [Columns.Name]: true,
      [Columns.ExternalId]: false,
      [Columns.LocationType]: false,
      [Columns.City]: false,
      [Columns.Sold]: true,
      [Columns.Coverage]: false,
      [Columns.Replenishment]: true,
      [Columns.ExpectedCoverage]: false,
      [Columns.AvgDailySales]: false,
      [Columns.SourceLocations]: false,
      [Columns.Region]: false,
      [Columns.TotalProducts]: true,
      [Columns.TotalSkus]: true,
      [Columns.ReplenishmentTime]: false,
      [Columns.Classifications]: false,
      [Columns.Brands]: false,
      [Columns.SoldDepleted]: false,
      [Columns.CoverageDepleted]: false,
      [Columns.AvgDailySalesDepleted]: false,
      [Columns.Constraints]: true,
    });

    const columnsOrder = ref<string[]>([
      Columns.Name,
      Columns.ExternalId,
      Columns.LocationType,
      Columns.City,
      Columns.Sold,
      Columns.Coverage,
      Columns.Replenishment,
      Columns.ExpectedCoverage,
      Columns.AvgDailySales,
      Columns.SourceLocations,
      Columns.Region,
      Columns.TotalProducts,
      Columns.TotalSkus,
      Columns.ReplenishmentTime,
      Columns.Classifications,
      Columns.Brands,
      Columns.SoldDepleted,
      Columns.CoverageDepleted,
      Columns.AvgDailySalesDepleted,
      Columns.Constraints,
    ]);

    const sorting = ref<{ sortBy: string | null; sortOrder: 'asc' | 'desc' }>({
      sortBy: null,
      sortOrder: 'asc',
    });

    const defaultFilters: ReplenishmentLocationsFilter = {
      region: [],
      city: [],
    };

    const appliedFilters = ref<ReplenishmentLocationsFilter>(cloneDeep(defaultFilters));

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);
    let fetchingPromise: Promise<void> | null = null;

    async function fetch() {
      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const { data } = await api.getUiStates({ key: StoreNames.ReplenishmentLocationsPage });

          const persistedState = data.data[0];

          if (persistedState) {
            // TODO: validate and merge data, persist again in case of invalid values
            columnsVisibility.value = {
              ...columnsVisibility.value,
              ...pick(persistedState.value.columnsVisibility, Object.keys(columnsVisibility.value)),
            };

            columnsOrder.value = persistedState.value.columnsOrder;
            sorting.value = persistedState.value.sorting;
            appliedFilters.value = {
              ...cloneDeep(defaultFilters),
              ...pick(persistedState.value.appliedFilters, Object.keys(defaultFilters)),
            };
          }

          const sortingSchema = z.object({
            sortBy: z.string().nullable().catch(sorting.value.sortBy),
            sortOrder: z.enum(['asc', 'desc']).catch(sorting.value.sortOrder),
          });

          sorting.value = sortingSchema.parse(sorting);

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      sorting: { ...sorting.value },
      appliedFilters: { ...appliedFilters.value },
    }));

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentLocationsPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          sorting: sorting.value,
          appliedFilters: appliedFilters.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      columnsVisibility,
      columnsOrder,
      sorting,
      appliedFilters,
    };
  },
);
