import { defineStore } from 'pinia';
import { z } from 'zod';
import { computed, ref, watch } from 'vue';
import { pick, cloneDeep, isEqual } from 'lodash-es';
import { useDebounceFn, whenever } from '@vueuse/core';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';
import type { ReplenishmentInventoriesFilter } from '../interfaces';

enum Columns {
  SkuChart = 'sku_chart',
  Location = 'location_name',
  LocationExternalId = 'location_external_id',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  SkuDescription = 'sku_description',
  Product = 'product',
  ProductExternalId = 'product_external_id',
  Style = 'style_names',
  Size = 'size_names',
  Color = 'color_names',
  Category = 'category_names',
  AG = 'assortment_group',
  UnitPrice = 'unit_price',
  Brand = 'brand_names',
  Season = 'season_names',
  TargetMarket = 'target_market_names',
  Replenishment = 'replenishment',
  ReplenishmentTime = 'replenishment_time',
  ShipmentDays = 'shipment_days',
  OptimalStock = 'optimal_stock',
  OptimalStockVariance = 'optimal_stock_variance',
  OptimalStockVariancePercentage = 'optimal_stock_variance_percentage',
  InventoryNeeded = 'inventory_needed',
  Stock = 'stock',
  WhInventory = 'wh_inventory',
  Sold = 'sold',
  SaleRate = 'sale_rate',
  Coverage = 'coverage',
  ReplenishmentGap = 'replenishment_gap',
  Constraints = 'constraints',
  SpecialEvent = 'special_event_names',
  DaysSinceAllocation = 'days_since_allocation',
}

export const useReplenishmentInventoriesPageStore = defineStore(
  StoreNames.ReplenishmentInventoriesPage,
  () => {
    const columnsVisibility = ref<Record<string, boolean>>({
      [Columns.SkuName]: true,
      [Columns.SkuExternalId]: false,
      [Columns.Location]: true,
      [Columns.LocationExternalId]: false,
      [Columns.SkuDescription]: false,
      [Columns.Product]: false,
      [Columns.ProductExternalId]: false,
      [Columns.Style]: false,
      [Columns.Size]: false,
      [Columns.Color]: false,
      [Columns.Category]: false,
      [Columns.AG]: false,
      [Columns.UnitPrice]: false,
      [Columns.Brand]: false,
      [Columns.Season]: false,
      [Columns.TargetMarket]: false,
      [Columns.Replenishment]: true,
      [Columns.ReplenishmentTime]: false,
      [Columns.ShipmentDays]: false,
      [Columns.OptimalStock]: true,
      [Columns.OptimalStockVariance]: false,
      [Columns.OptimalStockVariancePercentage]: false,
      [Columns.InventoryNeeded]: true,
      [Columns.Stock]: true,
      [Columns.WhInventory]: true,
      [Columns.Sold]: false,
      [Columns.SaleRate]: true,
      [Columns.Coverage]: false,
      [Columns.ReplenishmentGap]: true,
      [Columns.Constraints]: true,
      [Columns.SpecialEvent]: false,
      [Columns.DaysSinceAllocation]: false,
    });

    const columnsOrder = ref<string[]>([
      Columns.SkuChart,
      Columns.Location,
      Columns.SkuName,
      Columns.LocationExternalId,
      Columns.SkuExternalId,
      Columns.SkuDescription,
      Columns.Product,
      Columns.ProductExternalId,
      Columns.Style,
      Columns.Size,
      Columns.Color,
      Columns.Category,
      Columns.AG,
      Columns.UnitPrice,
      Columns.Brand,
      Columns.Season,
      Columns.TargetMarket,
      Columns.Replenishment,
      Columns.ReplenishmentTime,
      Columns.ShipmentDays,
      Columns.OptimalStock,
      Columns.OptimalStockVariance,
      Columns.OptimalStockVariancePercentage,
      Columns.InventoryNeeded,
      Columns.Stock,
      Columns.WhInventory,
      Columns.Sold,
      Columns.SaleRate,
      Columns.Coverage,
      Columns.ReplenishmentGap,
      Columns.Constraints,
      Columns.SpecialEvent,
      Columns.DaysSinceAllocation,
    ]);

    const sorting = ref<{ sortBy: string | null; sortOrder: 'asc' | 'desc' }>({
      sortBy: null,
      sortOrder: 'asc',
    });

    const defaultFilters: ReplenishmentInventoriesFilter = {
      location_external_id: [],
      sku_external_id: [],
      product_external_id: [],
      categories: [],
      brands: [],
      seasons: [],
      styles: [],
      replenishment: [null, null],
      optimal_stock: [null, null],
      optimal_stock_variance: [null, null],
      constraints: [],
      optimal_stock_variance_percentage: [null, null],
      special_event_names: [],
      days_since_allocation: [null, null],
    };

    const appliedFilters = ref<ReplenishmentInventoriesFilter>(cloneDeep(defaultFilters));

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);
    let fetchingPromise: Promise<void> | null = null;

    async function fetch() {
      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const { data } = await api.getUiStates({ key: StoreNames.ReplenishmentInventoriesPage });

          const persistedState = data.data[0];

          if (persistedState) {
            // TODO: validate and merge data, persist again in case of invalid values
            columnsVisibility.value = {
              ...columnsVisibility.value,
              ...pick(persistedState.value.columnsVisibility, Object.keys(columnsVisibility.value)),
            };

            columnsOrder.value = persistedState.value.columnsOrder;
            sorting.value = persistedState.value.sorting;
            appliedFilters.value = {
              ...cloneDeep(defaultFilters),
              ...pick(persistedState.value.appliedFilters, Object.keys(defaultFilters)),
            };
          }

          const sortingSchema = z.object({
            sortBy: z.string().nullable().catch(sorting.value.sortBy),
            sortOrder: z.enum(['asc', 'desc']).catch(sorting.value.sortOrder),
          });

          sorting.value = sortingSchema.parse(sorting);

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      sorting: { ...sorting.value },
      appliedFilters: { ...appliedFilters.value },
    }));

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentInventoriesPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          sorting: sorting.value,
          appliedFilters: appliedFilters.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      columnsVisibility,
      columnsOrder,
      sorting,
      appliedFilters,
    };
  },
);
