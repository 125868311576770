import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { isEqual } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

export const useSkuChartStore = defineStore(StoreNames.SkuChart, () => {
  const visibleMetrics = ref(['at_site', 'in_transit', 'optimal_stock', 'sale']);

  const api = useUiStatesApi();

  const fetching = ref(false);
  const fetched = ref(false);

  let fetchingPromise: Promise<void> | null = null;

  async function fetch() {
    if (!fetchingPromise) {
      fetchingPromise = (async () => {
        fetching.value = true;

        const { data } = await api.getUiStates({
          key: StoreNames.SkuChart,
        });

        const persistedState = data.data[0];

        if (persistedState) {
          // TODO: validate and merge data, persist again in case of invalid values
          visibleMetrics.value = persistedState.value.visibleMetrics;
        }

        fetching.value = false;
        fetched.value = true;
        fetchingPromise = null;
      })();
    }

    return fetchingPromise;
  }

  const persistingValue = computed(() => ({
    visibleMetrics: [...visibleMetrics.value],
  }));

  async function persist() {
    await api.saveUiState({
      key: StoreNames.SkuChart,
      value: {
        visibleMetrics: visibleMetrics.value,
      },
    });
  }

  const debouncedPersist = useDebounceFn(persist, 3000);
  const autoPersistEnabled = ref(false);

  watch(
    persistingValue,
    (value, oldValue) => {
      if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
        debouncedPersist();
      }
    },
    {
      deep: true,
    },
  );

  whenever(
    fetched,
    () => {
      autoPersistEnabled.value = true;
    },
    { once: true },
  );
  return {
    fetching,
    fetched,
    fetch,
    visibleMetrics,
  };
});
