import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { isEqual, pick } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

enum Columns {
  SkuChart = 'sku_chart',
  Size = 'size',
  SkuName = 'sku_name',
  SkuExternalId = 'sku_external_id',
  Replenishment = 'replenishment',
  Sold = 'sold',
  SalesRate = 'salesRate',
  Stock = 'stock',
  OptimalStock = 'optimalStock',
  WarehouseInventory = 'warehouseInventory',
  WhNames = 'warehouseName',
  NextReplenishment = 'replenishmentTime',
  Coverage = 'coverage',
  ExpectedCoverage = 'expectedCoverage',
  Constraints = 'constraints',
}

export const useReplenishmentLocationDetailsPageStore = defineStore(
  StoreNames.ReplenishmentLocationDetailsPage,
  () => {
    const columnsVisibility = ref<Record<string, boolean>>({
      [Columns.SkuChart]: true,
      [Columns.Size]: true,
      [Columns.SkuName]: false,
      [Columns.SkuExternalId]: false,
      [Columns.Replenishment]: true,
      [Columns.Sold]: true,
      [Columns.SalesRate]: false,
      [Columns.Stock]: true,
      [Columns.OptimalStock]: true,
      [Columns.WarehouseInventory]: false,
      [Columns.WhNames]: false,
      [Columns.NextReplenishment]: false,
      [Columns.Coverage]: false,
      [Columns.ExpectedCoverage]: false,
      [Columns.Constraints]: true,
    });

    const columnsOrder = ref<string[]>([
      Columns.SkuChart,
      Columns.Size,
      Columns.SkuName,
      Columns.SkuExternalId,
      Columns.Replenishment,
      Columns.Sold,
      Columns.SalesRate,
      Columns.Stock,
      Columns.OptimalStock,
      Columns.WarehouseInventory,
      Columns.WhNames,
      Columns.NextReplenishment,
      Columns.Coverage,
      Columns.ExpectedCoverage,
      Columns.Constraints,
    ]);

    const width = ref(680);

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);
    let fetchingPromise: Promise<void> | null = null;

    async function fetch() {
      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const { data } = await api.getUiStates({
            key: StoreNames.ReplenishmentLocationDetailsPage,
          });

          const persistedState = data.data[0];

          if (persistedState) {
            // TODO: validate and merge data, persist again in case of invalid values
            columnsVisibility.value = {
              ...columnsVisibility.value,
              ...pick(persistedState.value.columnsVisibility, Object.keys(columnsVisibility.value)),
            };

            columnsOrder.value = persistedState.value.columnsOrder;
            width.value = persistedState.value.width;
          }

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      width: width.value,
    }));

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentLocationDetailsPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          width: width.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      columnsVisibility,
      columnsOrder,
      width,
    };
  },
);
