import Keycloak from 'keycloak-js';
import mitt from 'mitt';
import type { Emitter } from 'mitt';
import { log } from '../../shared/utils';
import { Transport, type TransportResponse } from './transport';
import type { UserInfo, UserInfoUpdatePayload } from './interfaces';

export interface AuthStateChangeEvent {
  authenticated: boolean;
}

type AuthEvents = {
  stateChange: AuthStateChangeEvent;
};

type AuthStateChangeHandler = (event: AuthStateChangeEvent) => void;

export interface AuthOptions {
  url: string;
  realm: string;
  clientId: string;
  silentCheckSsoRedirectUri: string;
  logoutRedirectUri: string;
  transport: Transport;
}

export class Auth {
  private readonly emitter: Emitter<AuthEvents>;

  private initPromise: Promise<void> | null;
  private readonly transport: Transport;
  private readonly logoutRedirectUri: string;
  private readonly silentCheckSsoRedirectUri: string;
  private readonly keycloak: Keycloak;

  constructor(options: AuthOptions) {
    this.emitter = mitt<AuthEvents>();
    this.transport = options.transport;
    this.logoutRedirectUri = options.logoutRedirectUri;
    this.silentCheckSsoRedirectUri = options.silentCheckSsoRedirectUri;
    this.initPromise = null;

    this.keycloak = new Keycloak({
      url: options.url,
      realm: options.realm,
      clientId: options.clientId,
    });

    this.keycloak.onTokenExpired = () => this.keycloak.updateToken(0);
    this.keycloak.onAuthSuccess = () => {
      log('keycloak auth success');
      this.emitStateChange();
    };
    this.keycloak.onAuthLogout = () => {
      this.emitStateChange();
    };
    this.keycloak.onAuthRefreshError = () => {
      log('keycloak auth refresh error');
      this.keycloak.clearToken();
    };
    this.keycloak.onAuthError = () => {
      log('keycloak auth error');
      this.keycloak.clearToken();
    };
  }

  private emitStateChange(): void {
    this.emitter.emit('stateChange', {
      authenticated: this.isAuthenticated(),
    });
  }

  async init() {
    if (!this.initPromise) {
      log('initializing keycloak instance');

      this.initPromise = new Promise((resolve, reject) => {
        try {
          this.keycloak
            .init({
              checkLoginIframe: false,
              enableLogging: false,
              onLoad: 'check-sso',
              silentCheckSsoRedirectUri: this.silentCheckSsoRedirectUri,
            })
            .then(() => {
              log('keycloak instance is ready');
              resolve();
            });
          return this.initPromise;
        } catch (error) {
          reject(error);
        }
      });
    }

    return this.initPromise;
  }

  async getToken() {
    await this.keycloak.updateToken(30);
    return this.keycloak.token;
  }

  async logout(redirectUri?: string) {
    await this.keycloak.logout({
      redirectUri:
        redirectUri ??
        this.keycloak.createLoginUrl({
          redirectUri: this.logoutRedirectUri,
        }),
    });
  }

  async login(redirectUri?: string) {
    await this.keycloak.login({
      prompt: 'login',
      redirectUri,
    });
  }

  isAuthenticated(): boolean {
    return !!this.keycloak.authenticated;
  }

  async getUserInfo(): Promise<UserInfo | undefined> {
    const info = await this.keycloak.loadUserInfo();
    return info as UserInfo;
  }

  updateUserInfo(updatedUserInfo: UserInfoUpdatePayload): Promise<TransportResponse<void>> {
    const accountUrl = this.keycloak.createAccountUrl().split('?')[0];
    return this.transport.post<void>(accountUrl, updatedUserInfo);
  }

  changeUserPassword() {
    this.keycloak.login({ action: 'UPDATE_PASSWORD' });
  }

  createLoginUrl(redirectUri: string) {
    return this.keycloak.createLoginUrl({
      redirectUri: redirectUri,
    });
  }

  onStateChanged(handler: AuthStateChangeHandler): () => void {
    this.emitter.on('stateChange', handler);
    return () => this.emitter.off('stateChange', handler);
  }
}
