import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useApi } from '../services/api';
import { StoreNames } from '../shared/store-names';

interface CustomDataSchema {
  [scope: string]: [{ key: string; type: 'string' | 'float' | 'boolean' | 'datetime' }];
}

export const useAccountStore = defineStore(StoreNames.Account, () => {
  const name = ref('');
  const locale = ref('');
  const currency = ref('');
  const whReplenishmentActive = ref(false);
  const timezone = ref('UTC');
  const customDataSchema = ref<CustomDataSchema>({});
  const accountLastReplenishmentDate = ref('');

  const api = useApi();

  const fetching = ref(false);
  const fetched = ref(false);

  async function fetch(force?: boolean) {
    if (fetching.value || (fetched.value && !force)) {
      return;
    }

    try {
      fetching.value = true;

      const { data } = await api.getAccountSettings();

      name.value = data.name;
      locale.value = data.locale;
      currency.value = data.currency;
      timezone.value = data.timezone_name || 'UTC';
      whReplenishmentActive.value = data.wh_replenishment_active;
      customDataSchema.value = data.custom_data_schema ?? {};
      accountLastReplenishmentDate.value = data.account_last_replenishment_date;

      fetched.value = true;
    } catch (error) {
      // TODO: error handler
    } finally {
      fetching.value = false;
    }
  }

  function clear() {
    name.value = '';
    locale.value = '';
    currency.value = '';
    timezone.value = 'UTC';
    whReplenishmentActive.value = false;
    customDataSchema.value = {};
  }

  return {
    name,
    locale,
    currency,
    whReplenishmentActive,
    timezone,
    customDataSchema,
    accountLastReplenishmentDate,
    fetching,
    fetch,
    fetched,
    clear,
  };
});
