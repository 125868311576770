import { defineStore } from 'pinia';
import { useApi } from '../services/api';
import { StoreNames } from '../shared/store-names';

interface State {
  Meilisearch: boolean;
  MetaMeilisearch: boolean;
}

export const useSystemServicesStatusStore = defineStore(StoreNames.SystemServicesStatus, {
  state: (): State => ({
    Meilisearch: true,
    MetaMeilisearch: true,
  }),
  actions: {
    async fetch(): Promise<void> {
      const api = useApi();

      const { data } = await api.getSystemServicesStatus();

      if (!data) {
        return;
      }

      this.Meilisearch = data.Meilisearch;
      this.MetaMeilisearch = data.MetaMeilisearch;
    },
    async clear(): Promise<void> {
      this.$reset();
    },
  },
});
