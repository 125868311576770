import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useDebounceFn, whenever } from '@vueuse/core';
import { isEqual, pick, intersection as lodashIntersection, union } from 'lodash-es';
import { z } from 'zod';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

enum Columns {
  SkuChart = 'sku_chart',
  Size = 'size',
  Replenishment = 'replenishment',
  Sold = 'sold',
  SalesRate = 'sale_rate',
  Stock = 'stock',
  OptimalStock = 'optimal_stock',
  WarehouseInventory = 'wh_qty',
  WhNames = 'wh_names',
  NextReplenishment = 'replenishment_time',
  Coverage = 'coverage',
  ExpectedCoverage = 'expected_coverage',
  Constraints = 'constraints',
}

export const useReplenishmentProductDetailsPageStore = defineStore(
  StoreNames.ReplenishmentProductDetailsPage,
  () => {
    const columnsVisibility = ref<Record<string, boolean>>({
      [Columns.SkuChart]: true,
      [Columns.Size]: true,
      [Columns.Replenishment]: true,
      [Columns.Sold]: true,
      [Columns.SalesRate]: false,
      [Columns.Stock]: true,
      [Columns.OptimalStock]: true,
      [Columns.WarehouseInventory]: false,
      [Columns.WhNames]: false,
      [Columns.NextReplenishment]: false,
      [Columns.Coverage]: false,
      [Columns.ExpectedCoverage]: false,
      [Columns.Constraints]: true,
    });

    const columnsOrder = ref<string[]>([
      Columns.SkuChart,
      Columns.Size,
      Columns.Replenishment,
      Columns.Sold,
      Columns.SalesRate,
      Columns.Stock,
      Columns.OptimalStock,
      Columns.WhNames,
      Columns.NextReplenishment,
      Columns.WarehouseInventory,
      Columns.Coverage,
      Columns.ExpectedCoverage,
      Columns.Constraints,
    ]);

    const width = ref(680);

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch() {
      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const { data } = await api.getUiStates({
            key: StoreNames.ReplenishmentProductDetailsPage,
          });

          const persistedState = data.data[0];

          const persistedStateValueSchema = z.object({
            columnsVisibility: z.record(z.string(), z.boolean()).default(columnsVisibility.value),
            columnsOrder: z.array(z.string()).default(columnsOrder.value),
            width: z.number().default(width.value),
          });

          const persistedStateValue = persistedStateValueSchema.parse(persistedState?.value || {});

          columnsVisibility.value = {
            ...columnsVisibility.value,
            ...pick(persistedStateValue.columnsVisibility, Object.keys(columnsVisibility.value)),
          };

          columnsOrder.value = union(
            lodashIntersection(persistedStateValue.columnsOrder, columnsOrder.value),
            columnsOrder.value,
          );

          width.value = persistedStateValue.width;

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    const persistingValue = computed(() => ({
      columnsVisibility: { ...columnsVisibility.value },
      columnsOrder: [...columnsOrder.value],
      width: width.value,
    }));

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentProductDetailsPage,
        value: {
          columnsVisibility: columnsVisibility.value,
          columnsOrder: columnsOrder.value,
          width: width.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );
    return {
      fetching,
      fetched,
      fetch,
      columnsVisibility,
      columnsOrder,
      width,
    };
  },
);
