export const lineColors = {
  phlox: '#E410E9',
  pictonBlue: '#45B1FF',
  paleMagentaPink: '#FF97C9',
  coral: '#FF824D',
  saffron: '#F8C136',
  emerald: '#53C279',
  royalPurple: '#8859AA',
  bittersweetShimmer: '#BF5454',
  rackley: '#5985A5',
  frenchRose: '#F94892',
  imperial: '#683769',
  turquoiseSurf: '#00C0C0',
  oliverDrab: '#759B24',
  celticBlue: '#2B65D6',
  salmon: '#F07C6B',
  californiaGold: '#B68D25',
};

export const lineColorsList = Object.values(lineColors);

export const barColors = {
  bar100: '#402BBD',
  bar60: '#988BF6',
  bar40: '#9EB3FD',
};
