import { defineStore } from 'pinia';
import { useDebounceFn, whenever } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { z } from 'zod';
import { isEqual } from 'lodash-es';
import { StoreNames } from '../../../shared/store-names';
import { useUiStatesApi } from '../../ui-states';

export const useReplenishmentOverviewPageStore = defineStore(
  StoreNames.ReplenishmentOverviewPage,
  () => {
    const visibleMetrics = ref<string[]>(['replenishment', 'sold']);

    const sorting = ref<{ sortBy: string | null; sortOrder: 'asc' | 'desc' }>({
      sortBy: 'replenishment',
      sortOrder: 'desc',
    });

    const api = useUiStatesApi();

    const fetching = ref(false);
    const fetched = ref(false);

    let fetchingPromise: Promise<void> | null = null;

    async function fetch() {
      if (!fetchingPromise) {
        fetchingPromise = (async () => {
          fetching.value = true;

          const { data } = await api.getUiStates({ key: StoreNames.ReplenishmentOverviewPage });

          const persistedState = data.data[0];

          if (persistedState) {
            // TODO: validate and merge data, persist again in case of invalid values
            visibleMetrics.value = persistedState.value.visibleMetrics;
            sorting.value = persistedState.value.sorting;
          }

          const sortingSchema = z.object({
            sortBy: z.string().nullable().catch(sorting.value.sortBy),
            sortOrder: z.enum(['asc', 'desc']).catch(sorting.value.sortOrder),
          });

          sorting.value = sortingSchema.parse(sorting);

          fetching.value = false;
          fetched.value = true;
          fetchingPromise = null;
        })();
      }

      return fetchingPromise;
    }

    const persistingValue = computed(() => ({
      visibleMetrics: { ...visibleMetrics.value },
      sorting: { ...sorting.value },
    }));

    async function persist() {
      await api.saveUiState({
        key: StoreNames.ReplenishmentOverviewPage,
        value: {
          visibleMetrics: visibleMetrics.value,
          sorting: sorting.value,
        },
      });
    }

    const debouncedPersist = useDebounceFn(persist, 3000);
    const autoPersistEnabled = ref(false);

    watch(
      persistingValue,
      (value, oldValue) => {
        if (autoPersistEnabled.value && !isEqual(value, oldValue)) {
          debouncedPersist();
        }
      },
      {
        deep: true,
      },
    );

    whenever(
      fetched,
      () => {
        autoPersistEnabled.value = true;
      },
      { once: true },
    );

    return {
      fetching,
      fetched,
      fetch,
      visibleMetrics,
      sorting,
    };
  },
);
