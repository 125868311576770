import axios from 'axios';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { StoreNames } from '../../../shared/store-names';
import { useI18nStore } from '../../../stores';
import { config } from '../../../app';

interface Keys {
  [key: string]: {
    text: string;
    description: string;
  };
}

interface MetastoreResponse {
  data: Array<{ key: string; translations: Array<{ text: string; description: string }> }>;
}

export const useSupersetKeyMetaStore = defineStore(StoreNames.SupersetKeyMeta, () => {
  const keys = ref<Keys>({});

  async function fetch(): Promise<void> {
    const i18nStore = useI18nStore();

    const { data } = await axios.get<MetastoreResponse>(
      `/items/keys?fields[]=key,translations.text,translations.description&deep[translations][_filter][languages_code][_eq]=${i18nStore.locale}&limit=999`,
      {
        baseURL: config.superset.metastoreBaseUrl,
      },
    );

    keys.value = data.data.reduce<Keys>((acc, item) => {
      acc[item.key] = {
        text: item.translations[0]?.text,
        description: item.translations[0]?.description,
      };

      return acc;
    }, {});
  }

  async function clear(): Promise<void> {
    const store = useSupersetKeyMetaStore();
    store.$reset();
  }
  return {
    fetch,
    clear,
    keys,
  };
});
