import { createSharedComposable } from '@vueuse/shared';
import type { UiState } from '../../interfaces';
import { useApi, type PaginationQuery, type PaginatedResponse } from '../../../../services/api';

export const useUiStatesApi = createSharedComposable(() => {
  const { transport } = useApi();

  function getUiStates(query?: PaginationQuery & { key?: string }) {
    return transport.get<PaginatedResponse<UiState>>('v1/user/ui_states', {
      query,
    });
  }

  function saveUiState(payload: { key: string; value: unknown }) {
    return transport.post<{ data: UiState }>('v1/user/ui_states', payload);
  }

  function deleteAllUiStates() {
    return transport.delete('v1/user/ui_states');
  }

  function deleteUiState(id: string) {
    return transport.delete(`v1/user/ui_states/${id}`);
  }

  return {
    getUiStates,
    saveUiState,
    deleteAllUiStates,
    deleteUiState,
  };
});
