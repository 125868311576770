import { createSharedComposable } from '@vueuse/shared';
import { ref, computed } from 'vue';

export const useAppReady = createSharedComposable(() => {
  const ready = ref(false);

  function announceAppReady() {
    ready.value = true;
  }

  return {
    ready: computed(() => ready.value),
    announceAppReady,
  };
});
